import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '@/pages/Home.vue'
import Login from '@/pages/Login.vue'
import Cameras from '@/pages/Cameras.vue'
import ViewCamera from '@/pages/ViewCamera.vue'
import Dashboard from '@/pages/Dashboard.vue'
import { mapGetters } from 'vuex'

import store from '@/store';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/app',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/app/',
        redirect: '/app/cameras',
      },
      {
        path: '/app/cameras',
        name: 'Cameras',
        component: Cameras,
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/app/cameras/:id',
        name: 'ViewCamera',
        component: ViewCamera,
        meta: {
          requiresAuth: true
        },
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach(async (to, from, next) => {
  const isLogin = to.name === 'Login';
  if(to.matched.some(record => record.meta.requiresAuth) || isLogin) {

    store.dispatch('auth/fetchTokens');
    let authenticated = store.getters['auth/isAuthed'];

    if (!authenticated && !isLogin) {
      next('/login');
    } else if(isLogin && authenticated) {
      next('/app');
    } else {
      next();
    }

  } else {
    next()
  }
});

export default router