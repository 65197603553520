<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center" dense>
      <v-col cols="12" sm="8" md="4" lg="4">
        <v-card elevation="0">
        	<router-link to="/"  class="font-weight-bold black--text text-decoration-none mb-8">
	          <h1 class="text-center">
	        		Cameras Management
	          </h1>
	        </router-link>
          <v-card-text>
            <v-form id="login-form" @submit.prevent="login">
              <v-text-field label="Enter your email" name="email" v-model="form.email" prepend-inner-icon="mdi-email" type="email" class="rounded-0 mb-2" outlined :rules="emailRules"></v-text-field>
              <v-text-field label="Enter your password" name="password" v-model="form.password" prepend-inner-icon="mdi-lock" type="password" class="rounded-0" outlined></v-text-field>
              <v-btn type="submit" form="login-form" class="rounded-0" color="brown lighten-2" x-large block dark :loading="loggingIn">Login</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
</style>

<script lang="ts">
	import Vue from 'vue';

	export default Vue.extend({
		name: 'Login',
		data: () => ({
			form: {
				email: '',
				password: '',
			},
			loggingIn: false,
			emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
		}),
		methods: {
			login: function () {
				this.loggingIn = true;
				this.$store.dispatch('auth/login', this.form)
				.then(() => this.$router.push('/app'))
				.finally(() => this.loggingIn = false)
			},
		},
	})
</script>