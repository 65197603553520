<template>
	<div>
		<v-app-bar app color="brown darken-4" dark>
			<div class="font-weight-bold">
	    	Cameras Manager
			</div>
	    <v-spacer></v-spacer>
	    <v-btn href="/login" color="brown lighten-2" dark>LOGIN</v-btn>
		</v-app-bar>
		<v-container fluid class="home-hero">
			<v-layout justify-center align-center column pa-5 fill-height>
			  <h1 class="display-4 font-weight-black white--text text-center mb-12">All your cameras at a glance</h1>
			  <p class="display-1 white--text text-center">Check status and other information of your cameras.</p>
			</v-layout>
		</v-container>
	</div>
</template>

<style>
	.home-hero {
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('https://cdn.wallpapersafari.com/51/5/hRazgP.jpg');
    background-size: cover;
    width: 100%;
    height: 100vh;
	}
</style>

<script lang="ts">
	import Vue from 'vue';

	export default Vue.extend({
		name: 'Home',
	})
</script>