<template>
	<v-container>
		<h1 class="mt-6 mb-6">
			Cameras
		</h1>

		<v-data-table
			class="row-pointer elevation-1"
			:items="cameras"
			:headers="headers"
			:loading="loading"
			@click:row="clickCamera"
		/>
	</v-container>
</template>

<style lang="css" scoped>
	.row-pointer >>> tbody tr :hover {
	  cursor: pointer;
	}
</style>

<script lang="ts">
	import Vue from 'vue';

	export default Vue.extend({
		name: 'Cameras',
		data: function () {
			return {
	      headers: [
	        {text: 'ID', value: 'id'},
	        {text: 'Name', value: 'name'},
	        {text: 'Online', value: 'online'},
	        {text: 'Firmware Status', value: 'firmwareStatus'}
	      ],
			}
		},
		computed: {
			cameras() {
				return this.$store.state.cameras.cameras
			},
			loading() {
				return this.$store.state.cameras.loadingAllCameras
			},
		},
		created(){
			// call the cameras action
			this.$store.dispatch('cameras/getAllCameras')
		},
		methods: {
			clickCamera: function(value) {
				this.$router.push('/app/cameras/'+value.id);
			}
		}
	})
</script>