import axios from "axios";
import store from "../store";
import router from "../router";

export default function axiosSetUp() {
  // Request interceptor
  axios.interceptors.request.use(
    function(config) {
      const token = store.getters['auth/accessToken'];
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    function(error) {
      return Promise.reject(error);
    }
  );

  // Response interceptor
  axios.interceptors.response.use(
    async function(response) {
    	await store.dispatch('setError', null);
      return response;
    },
    async function(error) {
      const originalRequest = error.config;

      if (
        error.response.status === 401 &&
        originalRequest.params?.grant_type === "refresh_token"
      ) {
        store.commit("auth/clearTokens");
        router.push("/login");
        return Promise.reject(error);

      } else if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        await store.dispatch("auth/refreshToken");
        return axios(originalRequest);
      }

      await store.dispatch('setError', error.response.data.detail);
      return Promise.reject(error);
    }
  );
}
