import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth';
import cameras from './cameras';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    error: null,
  },
  mutations: {
    setErrorMutation: function(state, error) {
      state.error = error;
    }
  },
  actions: {
    setError({ commit }, error){
      commit('setErrorMutation', error);
    }
  },
  modules: {
    auth,
    cameras,
  }
})
