<template>
	<v-toolbar class="brown darken-4" dark>
		<router-link class="font-weight-bold white--text text-decoration-none" to="/app">Camera Manager</router-link>
    <v-spacer></v-spacer>
    <v-btn
      color="brown lighten-2"
      @click="logout"
      :loading="loggingOut"
    >
      Logout
    </v-btn>
	</v-toolbar>
</template>

<style>
</style>

<script lang="ts">
	import Vue from 'vue';

	export default Vue.extend({
		name: 'Navbar',
		data: () => ({
			loggingOut: false,
		}),
		methods: {
			logout: function () {
				this.loggingOut = true;
				this.$store.dispatch('auth/logout')
				.then(() => this.$router.push('/login'))
				.catch(err => console.log(err))
				.finally(() => this.loggingOut = false)
			},
		}
	})
</script>