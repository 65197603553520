<template>
	<v-snackbar
    :value="error"
    color="red"
  >
    {{ error }}
  </v-snackbar>
</template>

<script>
	export default {
		props: {
			error: String
		}
	}
</script>