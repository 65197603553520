import axios from 'axios';
import { Mutation } from 'vuex';

const baseURL =	process.env.VUE_APP_API_BASE_URL;

const initState = {
	cameras: [],
	camera: null,
	loadingAllCameras: false,
}

export default {
	namespaced: true,
	state: initState,
  mutations: {
  	setAllCameras: function(state, cameras) {
  		state.cameras = cameras
  	},
  	setCamera: function(state, camera) {
  		state.camera = camera
  	},
  	setLoadingAllCameras: function(state, loading) {
  		state.loadingAllCameras = loading
  	},
  },
  getters: {
  },
  actions: {
  	getCamera({ commit }, id) {
  		return new Promise((resolve, reject) => {

  			const cam = axios.get(`${baseURL}cameras/${id}`);
  			const status = axios.get(`${baseURL}cameras/${id}/status`);
  			const info = axios.get(`${baseURL}cameras/${id}/deviceInfo`);
  			const details = axios.get(`${baseURL}cameras/${id}/connection/details`);

  			axios.all([cam, status, info, details])
  			.then(axios.spread((...responses) => {
				  const camResp = responses[0].data
				  const statusResp = responses[1].data
				  const infoResp = responses[2].data
				  const detailsResp = responses[3].data

				  commit(
						"setCamera",
						{
							name: camResp.name,
							online: statusResp.online ? 'online' : 'offline',
							firmwareStatus: statusResp.firmwareStatus,
							brand: infoResp.brand,
							model: infoResp.model,
							globalHost: detailsResp.globalHost,
						}
					);
					resolve(responses);
				}))
				.catch(errors => {
				  reject(errors);
				})
  		})
  	},
  	getAllCameras({ commit }) {
  		return new Promise((resolve, reject) => {
  			commit("setLoadingAllCameras", true);
				axios({
				  method: 'get',
				  url: `${baseURL}cameras`,
				})
				.then(
					response => {
						axios({
						  method: 'get',
						  url: `${baseURL}cameras/all/status`,
						})
						.then(
							responseStatus => {
								commit(
									"setAllCameras",
									response.data.map(
										cam => {
											const status = responseStatus.data.find(s => s.cameraId === cam.cameraId);
											return {
												id: cam.cameraId,
												name: cam.name,
												online: status.online ? 'online' : 'offline',
												firmwareStatus: status.firmwareStatus,
											}
										}
									)
								);
								commit("setLoadingAllCameras", false);
								resolve(response);
							}
						)
					}
				)
				.catch(
					error => {
						reject(error);
					}
				);
  		})
  	},
  },
}