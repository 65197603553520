<template>
	<v-container class="app-container flex-grow-1 pa-0" fluid>

		<Navbar />

		<v-main fill-height>
      <v-container>
				<router-view />
			</v-container>
		</v-main>
	</v-container>
</template>

<style>
	.app-container{
		background: #f1f5f9;
	}
</style>

<script>
	import Navbar from '@/components/layouts/Navbar';

	export default {
		components: {
	    Navbar,
	  },
	}
</script>