<template>
	<v-container>
		<v-breadcrumbs class="pa-0" :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

		<h1 class="mt-3 mb-6">
			Camera: {{ id }}
		</h1>
		<v-progress-circular
			v-if="loading"
      indeterminate
      color="primary"
    />
		<v-container v-if="!loading">
			<v-row>
	      <v-col
	      >
	        <v-card
	          class="pa-3 elevation-1"
	        >
	          <div class="text-overline">
		          Name
		        </div>
		        <div class="text-h5">
		          {{ camera.name }}
		        </div>
	        </v-card>
	      </v-col>
	      <v-col
	      >
	        <v-card
	          class="pa-3 elevation-1"
	        >
	          <div class="text-overline">
		          Status
		        </div>
		        <div class="text-h5">
		          {{ camera.online }}
		        </div>
	        </v-card>
	      </v-col>
	      <v-col
	      >
	        <v-card
	          class="pa-3 elevation-1"
	        >
	          <div class="text-overline">
		          Firmware Status
		        </div>
		        <div class="text-h5">
		          {{ camera.firmwareStatus }}
		        </div>
	        </v-card>
	      </v-col>
	    </v-row>

	    <v-row>
	      <v-col
	      >
	        <v-card
	          class="pa-3 elevation-1"
	        >
	          <div class="text-overline">
		          Brand
		        </div>
		        <div class="text-h5">
		          {{ camera.brand }}
		        </div>
	        </v-card>
	      </v-col>
	      <v-col
	      >
	        <v-card
	          class="pa-3 elevation-1"
	        >
	          <div class="text-overline">
		          Model
		        </div>
		        <div class="text-h5">
		          {{ camera.model }}
		        </div>
	        </v-card>
	      </v-col>
	      <v-col
	      >
	        <v-card
	          class="pa-3 elevation-1"
	        >
	          <div class="text-overline">
		          IP Address
		        </div>
		        <div class="text-h5">
		          {{ camera.globalHost }}
		        </div>
	        </v-card>
	      </v-col>
	    </v-row>
		</v-container>
	</v-container>
</template>

<style>
</style>

<script lang="ts">
	import Vue from 'vue';

	export default Vue.extend({
		name: 'ViewCamera',
		data() {
			return {
				id: this.$route.params.id,
				loading: false,
				breadcrumbs: [
					{
	          text: 'Cameras',
	          disabled: false,
	          href: '/app/cameras',
	        },
	        {
	          text: 'Camera',
	          disabled: true,
	          href: '',
	        },
        ]
			}
		},
		computed: {
			camera() {
				return this.$store.state.cameras.camera
			},
		},
		created(){
			this.loading = true;
			this.$store.dispatch('cameras/getCamera', this.$route.params.id)
			.finally(() => this.loading = false)
		},
	})
</script>