import axios from 'axios';
import { Mutation } from 'vuex';
import store from "./";

const authString = btoa(`${process.env.VUE_APP_API_KEY}:${process.env.VUE_APP_SECRET}`);

const initState = {
	access_token: '',
	refresh_token: '',
}

export default {
	namespaced: true,
	state: initState,
  mutations: {
  	authSuccess: function(state, tokens) {
	    state.access_token = tokens.access_token
			state.refresh_token = tokens.refresh_token
	  },
  	clearTokens(state) {
  		state.refresh_token = '';
      state.access_token = '';
			localStorage.setItem('refresh_token', '');
      localStorage.setItem('access_token', '');
  	},
  	setRefreshToken: function(state, refresh_token) {
      state.refresh_token = refresh_token;
			localStorage.setItem('refresh_token', refresh_token);
    },
    setAccessToken: function(state, access_token) {
      state.access_token = access_token;
      localStorage.setItem('access_token', access_token);
    },
  },
  getters: {
    accessToken: state => state.access_token,
    refreshToken: state => state.refresh_token,
  	isAuthed: state => !!state.access_token
  },
  actions: {
  	refreshToken({ state, commit }) {
      return new Promise((resolve, reject) => {
				axios({
				  method: 'post',
				  url: `https://rest.cameramanager.com/oauth/token`,
				  params: {
				  	grant_type: 'refresh_token',
				  	scope: 'write',
				  	refresh_token: state.refresh_token,
				  },
				  headers: {
				  	'Authorization': 'Basic ' + authString,
				  },
				})
				.then(
					response => {
						const access_token = response.data.access_token;
						commit("setAccessToken", access_token);
						resolve(response);
					}
				)
				.catch(
					error => {
						reject(error);
					}
				);
  		})
    },
  	fetchTokens({ commit }) {
      commit('authSuccess', {access_token: localStorage.getItem('access_token'), refresh_token:  localStorage.getItem('refresh_token')});
    },
  	login({commit}, creds) {

  		return new Promise((resolve, reject) => {
				axios({
				  method: 'post',
				  url: `https://rest.cameramanager.com/oauth/token`,
				  params: {
				  	grant_type:'password',
				  	scope: 'write',
				  	username: creds.email,
				  	password: creds.password
				  },
				  headers: {
				  	'Authorization': 'Basic ' + authString,
				  },
				})
				.then(
					response => {
						const access_token = response.data.access_token;
						const refresh_token = response.data.refresh_token;

        		commit('setRefreshToken', refresh_token);
        		commit('setAccessToken', access_token);
						resolve(response);
					}
				)
				.catch(
					async (error) => {
						await store.dispatch('setError', error.response.data.error_description)
						reject(error)
					}
				);
  		})
  	},
  	logout({commit}) {
  		return new Promise((resolve, reject) => {
				axios({
				  method: 'delete',
				  url: `https://rest.cameramanager.com/rest/v2.0/users/self/tokens/current`,
				})
				.then(
					response => {
        		commit('clearTokens');
						resolve(response);
					}
				)
				.catch(
					error => {
						reject(error);
					}
				);
  		})
  	}
  },
}