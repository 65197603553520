<template>
  <v-app>
    <router-view/>
    <Error v-if="error" :error="error"/>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import Error from '@/components/Error';

export default Vue.extend({
  name: 'App',
  components: {
    Error
  },
  computed: {
    error() {
      return this.$store.state.error
    },
  },
});
</script>
